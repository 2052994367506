import './App.css';
import EmployeeTree from "./components/EmployeeTree"

function App() {
  return (
    <EmployeeTree />
  );
}

export default App;
